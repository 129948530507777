import { Grid, Stack } from '@mui/material';
import * as React from 'react';
import Layout from '../components/layout';
import SocialIcons from '../components/socialIcons';
import TextSection from '../components/textsection';

const ArtPage = () => {
  return (
    <Layout
      pageTitle="Art | EtherMagellan"
      pageDescription="Art created by EtherMagellan and what he's created that is currently available for purchase."
    >
      <Stack spacing={3}>
        <TextSection sectionTitle="My Art">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
              My art is very much still in its infancy, but the possibilities are so interesting in the procedural generation space that I
              have to go exploring! I've slowly been picking up blender for 3D Rendering and its python tools have been a boon to my art.
              Once I get the hang of things more and get a better GPU I'll release an NFT collection that inspires me. Until then enjoy some
              mid-process art I've made in the interim!
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/SbTX_LF8qT0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid>
            <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="100%"
                height="315"
                src="https://www.youtube.com/embed/yYsbH7scMN8"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </Grid>
          </Grid>
        </TextSection>
        <SocialIcons />
      </Stack>
    </Layout>
  );
};

export default ArtPage;
