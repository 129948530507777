import { Typography } from '@mui/material';
import * as React from 'react';

const TextSection = ({ sectionTitle, children }) => {
  return (
    <div maxWidth="md">
      <Typography variant="h4" sx={{ textAlign: 'center', fontStyle: 'none', paddingBottom: '16px', paddingTop:'8px' }}>
        {sectionTitle}
      </Typography>
      {/* <Divider variant="middle"/> */}
      {children}
    </div>
  );
};

export default TextSection;
